import React, { Component } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import styled from 'styled-components'
import Button from '../Layout/Button/Button'

class MailChimpSubscribe extends Component {

    constructor(props){
        super(props);
        this.emailEl = React.createRef();
 
        this.state = {
            formSubmissionSuccessful: null,
            formMessage: "",
            emailError: "",
            isVerified: false,
            capatchaValue: ""
        }

        this.submitHandler = this.submitHandler.bind(this);
    }

    validateForm = (email) => {

        if(email.trim().length === 0) {
            this.setState({emailError: "Enter an email addresss"});
            return false;
        }

        let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if(!emailValid) {
            this.setState({emailError: "Invalid email addresss"});
            return false;
        }

        return true;
    }

    
    clearErrors = () => {
        this.setState({
            formMessage: "",
            emailError: "",
        })
    } 
    
    submitHandler =  (event) => {

        event.preventDefault(); 
        
        let listFields = {}
        if(this.props.listFields) {
            listFields = this.props.listFields[0];
        }

        
        this.clearErrors();
        
        const email = this.emailEl.current.value;     
        let formValid = this.validateForm(email);

        if(!formValid) return;


        addToMailchimp(email, listFields).then(data => {
            if(data.result === "success"){

                this.setState({ formSubmissionSuccessful: true,
                    formMessage: "Thank you, the Guide has been emailed to you. Please check your spam folder or Promotions tab (Gmail) if you don't receive it."
                })

                trackCustomEvent({
                    category: this.props.trackCategory,
                    action: this.props.trackAction,
                    label: this.props.trackLabel
                  });

            }
            if(data.result === "error"){
                this.setState({ formSubmissionSuccessful: true,
                formMessage: data.msg
                })
            }
        });

    }

    render () {

        const successHTML = (
            <Message dangerouslySetInnerHTML={{__html: this.state.formMessage}} />
        );

        const formHTML = (
            <div>
                <form onSubmit={this.submitHandler}>
                    <Title>Download</Title>
                    <Headline>{this.props.title}</Headline>
                    <div>
                        <Input placeholder="Email Address" type="email" id="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"  ref={this.emailEl} />
                        <Error>{this.state.emailError}</Error>
                    </div>
                    <div>
                        <Button type="submit">{this.props.buttonLabel}</Button>
                    </div>
                </form>
            </div>
        );

        var displayHTML = "";
        if(this.state.formSubmissionSuccessful) {
            displayHTML = successHTML;
        } else {
            displayHTML = formHTML;
        }
      
      return (

        <Box>
             <FormContainter>
                {displayHTML}
            </FormContainter>
        </Box>
       
      )
    }
  }

  export default MailChimpSubscribe;

  const Box = styled.div`
    width: 310px;
    height: 310px;

    text-align: center;
    background-color: #f8f8f9;
    padding-top: 60px;
    padding-bottom: 20px;
    margin-left: 60px;
    margin-right: 60px;
    clip-path: circle(150px at center);

    @media (max-width: 599px) {
        margin-left: 5px;
        margin-right: 5px;
        padding-left: 20px;
        padding-right: 10px;
    }

  `
  const FormContainter = styled.div`
    display: inline-block;
    text-align: center;
  `


const Input = styled.input`
display: block;
outline: none;
width: 200px;
font-size: 1.2rem;
background-color: white;
color:  black;
padding: 5px;
border-color: #3479ae;
margin-bottom: 5px;
margin-top: 20px;

&::placeholder { 
    color: #CCCCCC;
    text-aligned: center;
} 

`

const Title = styled.div `
    margin-top: 40px;
    font-size: 2rem;

    font-weight: 400;
    letter-spacing: -0.1rem;
`


const Headline = styled.div `
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: -0.1rem;
`

const Error = styled.div `
    height: 20px;
    text-aligned: center;
    font-size: 0.8rem;
`

const Message = styled.div `
    margin: 90px 40px 10px 40px;
    text-aligned: center;
`


