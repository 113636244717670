import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import styled from 'styled-components'
import bullet from './tick.gif'
import MailChimpSubscribe from "../../components/MailChimpSubscribe/MailChimpSubscribe"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Grid2 from '../../components/Layout/Grid2/Grid2'
import GridItem from '../../components/Layout/GridItem/GridItem'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'


const GuidePage = ({location}) => 
	(
		<Layout>

			<SEO title="Guide and checklist for selling your prestige car privately"
				description="This guide and checklist is designed to make the process easier if you want to sell a prestige car yourself privately."
				location={location}
			/>

			<PageHeader
				title="Guide to selling a prestige used car"
				text="Selling a car privately, in particular a prestige car, can seem overwhelming. The truth is, 
						there is nothing overly complicated... but there are a lot of steps, and as most people only 
						sell a car every 3 or 4 years it’s not a process you can easily remember, rinse and repeat."
			/>

			<SectionPageMargin marginBottom="0px">
				<Grid2>
					<GridItem>
						<Heading2>Make the process easier</Heading2>
						<Paragraph1>
							This guide and checklist is designed to make the process easier if you want to sell a prestige car yourself privately. We hope that by following this process you can make life easier for yourself, and also present your car in the best possible way, to get a better price for your car.
						</Paragraph1>
						<Paragraph1>
							If you’re too busy to sell privately yourself, consider using Summon to help. We offer a full concierge service for selling your car privately. You can find more information here.
						</Paragraph1>
					</GridItem>
					<GridItem>
						<MailChimpSubscribe
							title="Guide and checklist"
							buttonLabel="Download"
							listFields={[{
								'group[292813][1]': '1'
							}]}
							trackCategory="Download"
							trackAction="MailChimp"
							trackLabel="Summon Guide and checklist"
						/>
					</GridItem>
				</Grid2>
			</SectionPageMargin>

			<SectionPageMargin>
				<GuideStyle>
					<Paragraph1>
						Selling a car privately, in particular a prestige car, can seem overwhelming. The truth is, there is nothing overly complicated... but there are a lot of steps, and as most people only sell a car every 3 or 4 years it’s not a process you can easily remember, rinse and repeat.
					</Paragraph1>
					
					
					<Paragraph1>
						This guide and checklist is designed to make the process easier if you want to sell a prestige car yourself privately. We hope that by following this process you can make life easier for yourself, and also present your car in the best possible way, to get a better price for your car.
					</Paragraph1>
					<Paragraph1>
						If you’re too busy to sell privately yourself, consider using Summon to help. We offer a full concierge service for selling your car privately. You can find <a title="Summon" href="https://www.summon.com.au">more information here</a>.
					</Paragraph1>

					<HR />
					
					<Heading2>Preparing your car for sale</Heading2>
					<Paragraph1>
						First things first; you will need to find and prepare all the documents and information needed to advertise and sell your car;
					</Paragraph1>
					<ul>
						<li>Have ready <strong>vehicle details</strong> including; car registration, rego expiry, VIN (vehicle information number)</li>
						<li>Check any specific State Government <strong>regulations</strong> for selling a car privately; <a href="https://www.nsw.gov.au/services/services-by-topic/transport-and-motoring/buying-or-selling-a-car/">NSW</a>, <a href="https://www.vicroads.vic.gov.au/registration/buy-sell-or-transfer-a-vehicle">Victoria</a>, <a href="https://www.qld.gov.au/transport/buying/rules">Queensland</a>, <a href="https://www.sa.gov.au/topics/driving-and-transport/vehicles/buying-and-selling-a-vehicle">South Australia</a>, <a href="https://www.transport.wa.gov.au/licensing/buy-sell-or-transfer-a-vehicle.asp">Western Australia</a>, <a href="https://www.transport.tas.gov.au/registration/buying-selling">Tasmania</a>, <a href="https://www.accesscanberra.act.gov.au/app/answers/detail/a_id/82/~/motor-vehicle-registration-transfer">Australian Capital Territory</a>, <a href="https://nt.gov.au/driving/rego/check,-renew-or-transfer-your-registration/buying-selling-a-used-vehicle-registration">Northern Territory</a> - in particular pay attention to requirements for Road Worthy Certificate (RWC) or Safety Certificates</li>
						<li>Get an up-to-date <strong>service history</strong> for your car - if your logbooks are not 100% current then request a statement from your service centre. For prestige cars, owners will be reassured by servicing with approved service centres, if this is the case with your car then mention it in the advertising</li>
						<li>If you have <strong>finance</strong> owing on the car, request a statement from the loan provider so you know the final payout figures (after any early exit fees, interest and the like)</li>
						<li>Check if the car is still under manufacturers <strong>warranty</strong>, or an extended warranty. If it is, be sure to call this out (with remaining months/years) in your listing adverts</li>
						<li>Collect your <strong>proof of ownership</strong> documentation (e.g. receipt of purchase)</li>
						<li>Consider getting a <strong>vehicle history</strong> report from CarFacts.com.au ($34) which can be provided to interested buyers</li>
						<li>Rummage around your filing cabinets for your <strong>owners manual</strong> and spare keys</li>
					</ul>

					<HR />

					<Heading2>How much is your car worth?</Heading2>
					<Paragraph1>
						Now for the fun part… trying to establish a realistic price for your car. This is much harder than it looks, and private sellers often get this wrong (as do dealers!);
					</Paragraph1>
					<ul>
						<li>Request a comparison price from a <strong>dealership</strong> (either as a trade-in or straight purchase). If you are selling an Audi, take it to an Audi dealership - it’s unlikely BMW will offer you a great price unless you are buying a new car from them. If you are happy with the price from the dealer, you can skip most of the remaining tips! </li>
						<li>Request a comparison price from an <strong>online wholesaler</strong> such as <a href="http://www.sellyourcarfast.com.au/">Sell Your Car Fast</a> or <a href="http://www.sellyourcarfast.com.au/">Sell My Car</a>. Expect a price of 15% - 20% less than the market price</li>
						<li>Optional: seek valuations for your car from <strong>online price guides</strong> such as <a href="https://www.carsales.com.au/car-valuations/">Carsales.com.au</a>, <a href="https://www.manheim.com.au/home/landing/car-price-guide">Manheims auction results</a>, <a href="http://www.glassguide.com.au/mycarprice.aspx">Glass's Guide</a> or <a href="https://www.carsguide.com.au/price">Carsguide</a>. But take these with a grain of salt, we recently benchmarked these and found a huge discrepancy in the results. </li>
						<li>Research <strong>other cars available for sale</strong> (on Carsales.com.au, CarsGuide, Drive.com.au and Autotrader) that are comparable to your car, make a shortlist of five similar cars. The most important things to look for are the same make, model, series, badge, model year - and similar mileage. Also watch carefully for dealer listings vs private seller listings - Dealers are generally more accurate / realistic in pricing than private sellers. From this research, you should have a good benchmark from which to price your car</li>
						<li>Based on your research, <strong>decide</strong> on a final price to advertise the car for sale, but be sure to allow room for negotiation with any interested buyer</li>
						<li>Use <a href="https://help.carsales.com.au/hc/en-gb/articles/204556705-What-is-PriceAssist-?">Carsales.com.au PriceAssist</a> to <strong>check</strong> your price against current listed cars and cars that have sold in the last 12 months. Unfortunately PriceAssist is only available <strong>after</strong> you have paid for and listed your car for sale, after which you can adjust the price if you wish to</li>
					</ul>

					<HR />

					<Heading2>Car photography</Heading2>
					<Paragraph1>
						Here at Summon, it makes us cringe every time we see a car listed for sale that is either a) covered in dirt, and/or b) photographed with an iPhone 3 in a dark garage. A small investment in presenting your car at it’s best will ensure more interest and ultimately a better price and faster sale;
					</Paragraph1>
					<ul>
						<li>Start by giving your car a thorough <strong>clean inside and out.</strong> Make sure you clean all the tricky spots like boot openings, door openings and bonnet. Attention to detail now will help with photos but also when you have people inspecting the car later. Empty out any personal items such as deodorants, door storage areas and the boot. If you’re busy, get the car professionally cleaned.</li>
						<li>If the paintwork is looking tired, consider getting <strong>a professional detail</strong>. This will also be a benefit for people inspecting the car. First impressions count!</li>
						<li>Find a <strong>good location</strong> for photos, ideally not at your house (for privacy reasons), but perhaps a nearby street that is quiet and has good light. We prefer not taking photos at the beach, as although it can look good, potential buyers might think the car has been exposed to moisture and salt</li>
						<li>Timing your photo shoot; the <strong>ideal time</strong> to take photos is in the early morning or late afternoon (in summer) when you can avoid harsh shadows and glare. </li>
						<li>If you are selling a prestige car, consider hiring a <strong>professional photographer</strong>. You can hire photographers on-demand from sites such as www.snappr.co and for a few hundred dollars you can have your car looking a million bucks</li><li>Take as many photos as your ad will allow, being sure to take <strong>good quality</strong> exterior shots from every angle. Get down low to frame the shot, and take care that the background is clear of distractions. Take photos of each wheel, and interior and the boot. Take photos of any scratches or damage to the car.</li>
					</ul>
					
					<HR />

					<Heading2>Advertising your car for private sale</Heading2>
					<Paragraph1>
						Now that you have amazing photos, we need to show them to the world and attract a buyer. There are several popular classified sites, and some are better suited to particular cars than others. For prestige cars, the most buyers are looking on Carsales.com.au - but listing on other marketplaces is free so also worth doing.
					</Paragraph1>
					<ul>
						<li>You should already have collected all the details about your car, now you need to write an honest, persuasive and <strong>compelling description</strong> to go with your photos. Channel your inner writer and use the AIDA framework: Attention, Interest, Desire and Action!</li>
						<li>Highlight the positives of the car, but also be honest about any shortcomings</li>
						<li>Be sure to list any <strong>options</strong> on the car in the description</li>
						<li>Does the car have a <strong>warranty</strong>? If so, be sure to mention this and when it expires</li>
						<li><strong>Advertise</strong> your car for sale on Carsales.com.au, and then also consider Drive.com.au, Autotrader and Carsguide, eBay and Facebook Marketplace</li>
						<li>If you are selling a prestige car, we recommend paying for a Premium Listing on Carsales.com.au - this has a big effect on the number of people who will see your car listing</li>
						<li>Also consider listing your car on car <strong>enthusiast sites</strong>, for example if you are selling a Porsche you could list it on the PFA (<a href="https://porscheforum.com.au/">porscheforum.com.au</a>) - you need to be a paid member to do this</li>
						<li>Also look into <strong>car club</strong> Web sites, for example Porsche Club Victoria members can list cars (for free) on the Marketplace on the PCV Web site</li><li>When you start receiving enquiries, be careful to <strong>avoid scams and frauds</strong>, these are common on the various car marketplaces</li><li>You will also likely receive messages and calls from people wanting to grab a bargain and make <strong>low-ball offers</strong>. This is frustrating but part of the game, be patient and respectful, and firm with the price you want</li>
					</ul>

					<HR />

					<Heading2>Inspections and Test drives</Heading2>
					<Paragraph1>
						Once you have a serious buyer interested in the car, they will want to see (and probably) drive the car before making an offer. This is a good time to be overly paranoid, as although it’s uncommon that people steal cars while arranging a ‘test-drive’ it does happen (it happened to a friend of mine in fact).
					</Paragraph1>
					<ul>
						<li>Check the car’s <strong>tyre pressures</strong>, and also tread levels</li>
						<li>Start by <strong>getting the buyers name and phone number</strong> before arranging the test-drive, and check the number is real. When the buyer arrives for the test-drive, ask for a copy of their drivers licence and take a photo of it.</li>
						<li>Check with your <strong>insurance</strong> policy that it will cover another driver (sometimes this is limited to a certain age, so you might need to ask how old the buyer is)</li>
						<li>Give the car a good <strong>clean</strong> inside and out before meeting the buyer</li>
						<li>Never ask to meet at your house, meet somewhere nearby that you know and would be comfortable meeting a stranger at. Ideally somewhere that other people will be around. Consider having a friend or family member come along with you</li>
						<li>Don’t let the prospective buyer drive the car by themselves, you need to go with them. And don’t be tricked into getting out of the car during the drive, and giving them the opportunity to drive off without you</li>
						<li>If you meet a prospective buyer and don’t have a good vibe about them, don’t let them drive your car. <strong>Go with your gut</strong>.</li>
					</ul>

					<HR />

					<Heading2>Negotiation</Heading2>
					<Paragraph1>
						Hopefully you now have a buyer interested to make an offer on your car. You should hopefully have priced the car to allow for some room for negotiation, but also decided on the lowest price you will accept for the car.
					</Paragraph1>
					<Paragraph1>
						Negotiation is a science and an art-form, if you really want to geek out on negotiation skills you could read the excellent book; Never <a href="https://www.amazon.com/Never-Split-Difference-Negotiating-Depended/dp/0062407805">Split the Difference</a> by Chris Voss. This $20 book could save you hundreds or even thousands of dollars.
					</Paragraph1>

					<HR />

					<Heading2>Finalising the sale</Heading2>
					<Paragraph1>
						Once you have agreed on a price, you need to finalise the sale by receiving payment and doing the necessary paperwork.
					</Paragraph1>
					<ul>
						<li>Be sure to <strong>receive cleared</strong> funds in your account before handing over the car keys. These days a fund transfer from bank to bank will take place either same day or next day (depending on which bank you and the buyer are with)</li>
						<li>Provide the buyer with a <strong>receipt</strong> for the payment, including details of the car such as the make, model, year and VIN. The receipt should state the car is being ‘sold as seen’, unless you have agreed to make any repairs (or replace tyres) before handover</li>
						<li>Check the requirements for <strong>Transfer of Ownership</strong> in your state, for example in Victoria you will have to get a Roadworthy for the car</li>
						<li>Before handing over the car, remove all of your personal items, and if you have a personalised number plate.</li>
						<li>Be nice and give the car a really great <strong>clean</strong> (or a detail) inside and out before handing it over to the new owner</li>
						<li>Let your <strong>insurance</strong> company know that you have sold the car</li>
						<li>Remove the <strong>e-Tag</strong> (if you have a physical tag) and also update your details online to make sure you don’t incur further charges</li>
						<li>When you are handing over the car, give the buyer the keys (including any spares), accessories, the manual and service booklet.</li>
						<li>Only remove the advertising listings after you have received payment and finished handover</li>
					</ul>

					<HR />

					<Paragraph1>
						Now, that wasn’t so hard… was it?!
					</Paragraph1>

					<Paragraph1>
						Alternatively, if you have a prestige car valued at over $50,000, you could simply ask Summon to sell the car on your behalf, and skip almost all of the steps above (except for seeing the money in your bank account!). Contact us today for more information.
					</Paragraph1>

					<Paragraph1>
						If you liked this article, and are planning to buy a prestige used car soon, check out our <a href="//assets.ctfassets.net/4503lf2fbhu7/3gt3yCrVLjVBFIJsn8nxnB/92265a8f6de4e92e42306bb3902e29a8/Summon-Top-10-tips-buying-prestige.pdf">Top 10 tips to buying a used prestige car</a>.
					</Paragraph1>

				</GuideStyle>
			</SectionPageMargin>

			<ReadyToGetStarted />

		</Layout>

);



export default GuidePage

const GuideStyle = styled.div`
  li {
	  margin-left: -30px;
    padding: 5px;
	list-style: none;
	margin-bottom: 10px;
	line-height: 2rem;
	position: relative;
    padding-left: 30px;

	&:before {
		content: '';
		width: 15px;
		height: 15px;
		position: absolute;
		background-image: url('${bullet}');
		background-size: cover;
		background-position: center;
		left: 0;
		top: 15px;
	
	}

  }
`;

const HR = styled.hr`
	margin-top: 80px;
	margin-bottom: 40px;
	border: 1px solid #3479ae;
	width: 50%;
`;
